
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const value1 = ref('');

interface edit {
  dateAll: any;
  name: string;
  members: any;
  regions: any;
  note: string;
  totalSekolah: number;
  totalPendapatanTerbayar: number;
  totalPendapatanBelumTerbayar: number;
  totalPendapatan: number;
  
  fromMonth: number;
  fromYear: number;
  untilMonth: number;
  untilYear: number;

  showFilter: boolean;
}

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const edit = reactive<edit>({
      dateAll: [],
      name: "",
      members: [],
      regions: [],
      note: "",
      totalSekolah: 0,
      totalPendapatanTerbayar: 0,
      totalPendapatanBelumTerbayar: 0,
      totalPendapatan: 0,

      fromMonth: 1,
      fromYear: 2023,
      untilMonth: 1,
      untilYear: 2023,

      showFilter: false,
    });

    const getDetailFirstTime = () => {
      // console.log('get All');
      var today = new Date();
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      ApiService
        .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id + 
        '/detail?fromMonth='+mm+'&fromYear='+yyyy+
        '&untilMonth='+mm+'&untilYear='+yyyy+'&page=0&size=10&sort=salesName&dir=1')
        .then((res) => {
          // edit.name = res.data.name;
          edit.members = res.data.content;
          // edit.regions = res.data.regions;
          // edit.note = res.data.note;
          var jumlahSekolah = 0;
          var jumlahPendapatanTerbayar = 0;
          var jumlahPendapatanBelumTerbayar = 0;
          var jumlahPendapatan = 0;
          for (let i = 0; i < res.data.content.length; i++) {
            jumlahSekolah += res.data.content[i].globalSchools;
            jumlahPendapatanTerbayar += res.data.content[i].invoicePaid;
            jumlahPendapatanBelumTerbayar += res.data.content[i].invoiceUnpaid;
            jumlahPendapatan += res.data.content[i].totalInvoices;
          }

          edit.totalSekolah = jumlahSekolah;
          edit.totalPendapatanTerbayar = jumlahPendapatanTerbayar;
          edit.totalPendapatanBelumTerbayar = jumlahPendapatanBelumTerbayar;
          edit.totalPendapatan = jumlahPendapatan;
        })
        .catch((e) => {
          edit.members = [];
          toast.error(e.response.data.detail)

        });
    };
    
    const getId = () => {
      console.log('get All');
      ApiService
        .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id + 
        '/detail?fromMonth='+edit.fromMonth+'&fromYear='+edit.fromYear+
        '&untilMonth='+edit.untilMonth+'&untilYear='+edit.untilYear+'&page=0&size=10&sort=salesName&dir=1')
        .then((res) => {
          // edit.name = res.data.name;
          edit.members = res.data.content;
          // edit.regions = res.data.regions;
          // edit.note = res.data.note;
          var jumlahSekolah = 0;
          var jumlahPendapatanTerbayar = 0;
          var jumlahPendapatanBelumTerbayar = 0;
          var jumlahPendapatan = 0;
          for (let i = 0; i < res.data.content.length; i++) {
            jumlahSekolah += res.data.content[i].globalSchools;
            jumlahPendapatanTerbayar += res.data.content[i].invoicePaid;
            jumlahPendapatanBelumTerbayar += res.data.content[i].invoiceUnpaid;
            jumlahPendapatan += res.data.content[i].totalInvoices;
          }

          edit.totalSekolah = jumlahSekolah;
          edit.totalPendapatanTerbayar = jumlahPendapatanTerbayar;
          edit.totalPendapatanBelumTerbayar = jumlahPendapatanBelumTerbayar;
          edit.totalPendapatan = jumlahPendapatan;
        })
        .catch((e) => {
          edit.members = [];
          toast.error(e.response.data.detail)

        });
    };

    const getDetailTimAreaandKet = () => {
      ApiService
        .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id)
        .then((res) => {
          edit.name = res.data.name;
          edit.note = res.data.note;
        })
        .catch((e) => {
          
          toast.error(e.response.data.detail)

        });
    };

    const getTeamRegionDetailId = () => {
      ApiService
        .getWithoutSlug('crmv2/main_user/sales/team/' + route.params.id + '/region')
        .then((res) => {
          edit.regions = res.data;
        })
        .catch((e) => {
          
          toast.error(e.response.data.detail)

        });
    };
    function formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    function pilihTanggalAll(){
      console.log(edit.dateAll)
      var dateFromArray = edit.dateAll[0].split("-");
      var dateUntilArray = edit.dateAll[1].split("-");

      edit.fromMonth = dateFromArray[1];
      edit.fromYear = dateFromArray[0];
      edit.untilMonth = dateUntilArray[1];
      edit.untilYear = dateUntilArray[0];

      getId();
    };

    function getCurrentMonth(){
      // console.log('current');
      var today = new Date();
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      edit.dateAll = [yyyy+'-'+mm,yyyy+'-'+mm];
    };

    function ShowFilter(){
      if(edit.showFilter == false){edit.showFilter = true}else{edit.showFilter=false}
    };

    onMounted(() => {
      getDetailTimAreaandKet();
      getDetailFirstTime();
      getCurrentMonth();
      store.dispatch(Actions.VERIFY_AUTH_USER);
      // getId();
      getTeamRegionDetailId();

    });

    return {
      submitButton,
      edit,
      getCurrentMonth,
      getDetailTimAreaandKet,
      getId,
      getTeamRegionDetailId,
      formatPrice,
      pilihTanggalAll,
      getDetailFirstTime,
      ShowFilter,
    };
  },

  // data() {
  //   return {
  //     dateAll: "",
  //   };
  // }
});
