
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { ErrorMessage, Field, Form } from "vee-validate";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { ref } from "yup";

import Detail from "@/components/1crm/sales/sales-area/detail-sales-area.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Detail,
  },
  props: {
    widgetClasses: String,
  },

  data() {
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_USER);
    setCurrentPageBreadcrumbs("Detail Sales Area", ["Data"]);
  },

  computed: {
  
  },

  methods: {

  },

});
